$(document).ready(function(){

    $('[role~="main-menu"]').click(function(event){
        event.preventDefault();
        $('#main-menu').toggleClass('active');
    });

    $('[role~="main-menu-close"]').click(function(event){
        event.preventDefault();
        $('#main-menu').removeClass('active');
    });

});
